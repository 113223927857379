import * as React from 'react';
import loadable from '@loadable/component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { sectionList, FormTypes } from '../../constants/queryFragments';
import { graphql } from 'gatsby';
import GlobalStyles from '../../styles/globalStyles';
import { Helmet } from 'react-helmet';
import Footer from '../../components/footer/footer';
import TopBar from '../../components/topBar/topBar';
import NavigationBar from '../../components/navigationBar/navigationBar';
import PageHeader from '../../components/dynamic/pageHeader/pageHeader';
import FooterLinks from '../../components/footerLinks/footerLinks';
import SideButton from '../../components/sideButton/sideButton';
import * as Styled from '../../styles';
import logo from '../../images/og-logo-twitter.png';

const CommonPageTemplate = ({ data, location }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const sectionsArray = data?.allContentfulBurnabyRefinery?.edges?.find(
    (edge) => edge?.node?.node_locale === 'en-US'
  )?.node.sections;

  const metaData = data?.allContentfulBurnabyRefinery?.edges[0]?.node;
  const twitterImage = 'https://images.ctfassets.net/tatgxebmkmwo/X8hlhUAXNwpdlLCDJC801/ad35b8dc60ee27d393a4eb6047b91703/og-logo-twitter.png'

  return !loaded ? (
    <>
      <Helmet>
        <title>{metaData?.title + ' - Burnaby Refinery'}</title>

        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {metaData?.metadata && (
          <meta property="og:description" content={metaData?.metadata}></meta>
        )}
        {metaData?.title && (
          <meta property="og:title" content={metaData?.title}></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logo}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" content="https://burnabyrefinery.ca"></meta>
        {metaData?.title && (
          <meta name="twitter:title" content={metaData?.title}></meta>
        )}
        {metaData?.metadata && (
          <meta name="twitter:description" content={metaData?.metadata}></meta>
        )}
        <meta name="twitter:image" content={twitterImage}></meta>
        {metaData?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <GlobalStyles />
      <SideButton />
      <TopBar desktop />
      <Styled.Clear />
      <NavigationBar />
      <PageHeader header={metaData?.title} />
      <>
        {sectionsArray &&
          sectionsArray?.map((section, index) => {
            if (
              section?.type &&
              sectionList?.includes(section?.type) &&
              !FormTypes?.includes(section?.type)
            ) {
              const SectionComponent = loadable(() =>
                import(
                  `../../components/dynamic/${section?.type}/${section?.type}`
                )
              );
              return (
                <SectionComponent
                  key={index}
                  sectionData={section}
                  location={location}
                />
              );
            }
            return <></>;
          })}
      </>
      <FooterLinks />
      <Footer />
    </>
  ) : (
    <>
      <Helmet>
        <title>{metaData?.title + ' - Burnaby Refinery'}</title>

        <meta http-equiv="X-UA-Compatible" content="IE=edge"></meta>
        {metaData.metadata && (
          <meta property="og:description" content={metaData.metadata}></meta>
        )}
        {metaData?.title && (
          <meta property="og:title" content={metaData?.title}></meta>
        )}
        <meta property="og:type" content="website"></meta>
        <meta property="og:image" content={logo}></meta>
        <meta property="og:url" content={process.env.GATSBY_DOMAIN_URL}></meta>
        {metaData?.title && (
          <meta name="twitter:title" content={metaData?.title}></meta>
        )}
        {metaData?.metadata && (
          <meta name="twitter:description" content={metaData?.metadata}></meta>
        )}
        <meta name="twitter:image" content={logo}></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        {metaData?.hasIndex === false && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <GlobalStyles />
      <SideButton />
      <TopBar desktop />
      <Styled.Clear />
      <NavigationBar />

      <PageHeader header={metaData?.title} />
      <>
        {sectionsArray &&
          sectionsArray?.map((section, index) => {
            if (section?.type && sectionList?.includes(section?.type)) {
              const SectionComponent = loadable(() =>
                import(
                  `../../components/dynamic/${section?.type}/${section?.type}`
                )
              );
              return (
                <SectionComponent
                  key={index}
                  sectionData={section}
                  location={location}
                />
              );
            }
            return <></>;
          })}
      </>
      <FooterLinks />
      <Footer />
    </>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulFaqs: Boolean!
    $contentfulcalenderList: Boolean!
  ) {
    allContentfulBurnabyRefinery(
      filter: { type: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          type {
            type
          }
          slug
          metadata
          title
          node_locale
          hasIndex
          sections {
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...FAQContent @include(if: $contentfulFaqs)
            ...CalenderList @include(if: $contentfulcalenderList)
          }
        }
      }
    }
  }
`;
